import React from 'react';
import Card from 'atoms/Card';
import Column from 'atoms/Column';
import Row from 'atoms/Row';
import { Loader } from 'atoms/Loader/Loader';
import { TEST_IDS } from 'constants/testIds';
import UserStatusIcon from 'components/UserStatusIcon';
import { Container, Message, TableHead } from './styles';
import { Employee } from '../../../../types/graphql';

type EmployeesTableProps = {
  getEmployee: (employeeId: string) => void;
  loading: boolean;
  data: { getEmployeesByCompanyId: Employee[] };
};

export const AdminEmployeesTable: React.FC<EmployeesTableProps> = ({
  getEmployee,
  loading,
  data,
}) => {
  const renderBody = () => {
    if (loading) return <Loader />;

    if (data?.getEmployeesByCompanyId?.length === 0) {
      return <Message>No employees for this company.</Message>;
    }
    return (
      <>
        {data?.getEmployeesByCompanyId
          .slice()
          .sort((a: Employee, b: Employee) =>
            a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }),
          )
          .map((employee: Employee, i: number) => {
            return (
              <Row onClick={() => getEmployee(employee.id)} selectable key={i}>
                <Column width={2} noMarginRight noMarginLeft>
                  <UserStatusIcon employee={employee} />
                </Column>
                <Column width={23}>{`${employee.firstName} ${employee.lastName}`}</Column>
                <Column width={35}>{employee.email}</Column>
                <Column width={40}>{employee.jobTitle}</Column>
              </Row>
            );
          })}
      </>
    );
  };

  return (
    <Card flex={3} data-testid={TEST_IDS.adminEmployeeTable}>
      <TableHead>
        <Column width={2} noMarginRight noMarginLeft></Column>
        <Column width={23}>Name</Column>
        <Column width={35}>Email</Column>
        <Column width={40}>Title</Column>
      </TableHead>
      <Container>{renderBody()}</Container>
    </Card>
  );
};
