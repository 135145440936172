import { MODAL_HEADER_HEIGHT } from 'atoms/ModalHeader/styles';
import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Styles = {
  Wrapper: styled.div`
    position: relative;
    height: 90vh;
    width: ${defaultTheme.spacing(160)};
    background-color: ${defaultTheme.colors.white};
    border-radius: 5px;
  `,
  StyledForm: styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: ${defaultTheme.spacing(10)};
    height: calc(90vh - ${MODAL_HEADER_HEIGHT});
    overflow: auto;
  `,
  Loading: styled.p`
    font-size: 13px;
  `,
  SuccessWrapper: styled.div`
    padding: ${defaultTheme.spacing(20)};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  SuccessTitle: styled.h3`
    font-size: 22px;
    font-weight: 400;
    margin-bottom: ${defaultTheme.spacing(8)};
  `,
  AllStates: styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.healthBlue};
    margin-top: ${defaultTheme.spacing(2)};
    padding: 0 ${defaultTheme.spacing(2)};
    font-weight: 500;
    letter-spacing: -0.03em;
    &:hover {
      cursor: pointer;
    }
  `,
  Row: styled.div`
    display: flex;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    > div {
      max-width: calc(50% - ${defaultTheme.spacing(3)});
    }
  `,
  ButtonWrapper: styled.div`
    max-width: 200px;
  `,
  InputMeta: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  ServerError: styled.p`
    text-align: center;
    display: block;
    margin: 0 0 ${defaultTheme.spacing(4)};
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.alertRed};
  `,
  ProfileImage: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: ${defaultTheme.spacing(8)};
  `,
};
