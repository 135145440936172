import { TextButton } from 'atoms/TextButton/TextButton';
import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { createPreviewImageFromFile } from 'services/image/createPreviewImageFromFile';
import { convertBlobToBase64 } from 'services/image/imageUploader';
import Person from 'assets/svgs/person.svg';
import { Styles } from './styles';
import { TEST_IDS } from 'constants/testIds';

type ProfileImagePickerProps = {
  size: number;
  uploadImage?(base64: string): void;
  showButton?: boolean;
  profileImageUrl?: string;
  disabled?: boolean;
};

export const ProfileImagePicker: FC<ProfileImagePickerProps> = ({
  profileImageUrl,
  uploadImage,
  showButton,
  size,
  disabled,
}) => {
  const [imagePreview, setImagePreview] = useState('');

  const onDrop = async (files: File[]) => {
    const preview = createPreviewImageFromFile(files[0]);
    const base64 = await convertBlobToBase64(files[0]);
    if (uploadImage) {
      uploadImage(base64);
      setImagePreview(preview);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 5000000,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    disabled,
  });

  return (
    <Styles.Wrapper {...getRootProps()} data-testid={TEST_IDS.addEmployeeModal.profileImagePicker}>
      {profileImageUrl && !imagePreview ? (
        <Styles.Image size={size} src={profileImageUrl} />
      ) : (
        <Styles.Avatar size={size}>
          {imagePreview ? <Styles.Image size={size} src={imagePreview} /> : <Person />}
        </Styles.Avatar>
      )}
      {showButton && (
        <>
          <TextButton
            onClick={() => {}}
            noSvg
            title={
              !!profileImageUrl || !!imagePreview
                ? 'Change profile picture'
                : 'Upload profile picture'
            }
          />
          <input {...getInputProps()} />
        </>
      )}
    </Styles.Wrapper>
  );
};
