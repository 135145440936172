import { Chevron } from 'atoms/Chevron';
import { TEST_IDS } from 'constants/testIds';
import React, { useState } from 'react';
import { Heading, Content, ContentData, FloatRight, Wrapper } from './styles';

type AccordionProps = {
  title: string;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({ title, children, defaultExpanded }) => {
  const [toggleOpen, setToggleOpen] = useState<boolean>(defaultExpanded || false);
  return (
    <Wrapper>
      <Heading
        onClick={() => setToggleOpen(!toggleOpen)}
        open={toggleOpen}
        data-testid={TEST_IDS.atoms.accordion.heading}
      >
        {title}
        <FloatRight>
          <Chevron type={toggleOpen ? 'up' : 'down'} />
        </FloatRight>
      </Heading>
      <Content open={toggleOpen} data-testid={TEST_IDS.atoms.accordion.content}>
        <ContentData open={toggleOpen}>{children}</ContentData>
      </Content>
    </Wrapper>
  );
};
