import { DropdownValue } from 'models/global';
import React from 'react';
import Select, { Options } from 'react-select';
import { singleSelectStyles } from './styles';
import { Error } from 'atoms/Error';

export type SingleSelectProps = {
  options: Options<DropdownValue>;
  handleSelect: (selectedOption: DropdownValue | null) => void;
  name: string;
  value?: DropdownValue;
  placeholder?: string;
  id?: string;
  error?: string | string[];
};

export const SingleSelect: React.FC<SingleSelectProps> = ({
  handleSelect,
  options,
  name,
  value,
  placeholder,
  id,
  error,
}) => {
  return (
    <div data-testid={`select_${name}`}>
      <Select
        styles={singleSelectStyles}
        hideSelectedOptions
        components={{
          IndicatorSeparator: () => null,
        }}
        isClearable
        noOptionsMessage={() => 'No options'}
        name={name}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={selectedOption => {
          handleSelect(selectedOption);
        }}
        id={id}
      />
      {error && <Error marginTop="0">{Array.isArray(error) ? error.join(',') : error}</Error>}
    </div>
  );
};
