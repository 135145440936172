import React from 'react';
import { TEST_IDS } from 'constants/testIds';

type ChevronProps = {
  type: 'up' | 'down';
  color?: string;
  opacity?: number;
  scale?: number;
};

export const Chevron: React.FC<ChevronProps> = ({
  type,
  color = '#20376A',
  opacity = 0.72,
  scale = 1,
}) => {
  if (type === 'up') {
    return (
      <svg
        width={13 * scale}
        height={8 * scale}
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={TEST_IDS.atoms.arrow.up}
      >
        <path
          d="M5.99102 0.816174L0.456013 6.36653C0.274771 6.54838 0.275076 6.8428 0.45695 7.02435C0.638801 7.20575 0.933386 7.20528 1.11477 7.02341L6.32033 1.80343L11.5259 7.0236C11.7073 7.20545 12.0017 7.20592 12.1836 7.02453C12.2747 6.93353 12.3203 6.8143 12.3203 6.69507C12.3203 6.57615 12.275 6.4574 12.1845 6.36655L6.64963 0.816174C6.56249 0.728589 6.4439 0.67944 6.32033 0.67944C6.19677 0.67944 6.07832 0.728729 5.99102 0.816174Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
  if (type === 'down') {
    return (
      <svg
        width={13 * scale}
        height={8 * scale}
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={TEST_IDS.atoms.arrow.down}
      >
        <path
          d="M6.64961 7.02355L12.1846 1.47319C12.3659 1.29134 12.3655 0.996921 12.1837 0.815375C12.0018 0.633969 11.7072 0.634438 11.5259 0.816313L6.32029 6.03629L1.11475 0.816125C0.933342 0.634274 0.638945 0.633805 0.457069 0.815187C0.365898 0.906194 0.320312 1.02542 0.320312 1.14465C0.320312 1.26357 0.365593 1.38233 0.456132 1.47317L5.99099 7.02355C6.07813 7.11113 6.19673 7.16028 6.32029 7.16028C6.44385 7.16028 6.5623 7.11099 6.64961 7.02355Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
  return null;
};
