import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Div = styled.div`
  display: flex;
  width: ${getSpacing(3)};
  height: ${getSpacing(3)};
  justify-content: center;
  align-items: center;
`;
