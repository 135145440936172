import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Wrapper = styled.div<{ last?: boolean; noPadding?: boolean }>`
  display: flex;
  align-items: center;
  ${props => props.last && `margin-right: ${getSpacing(5)};`}
  ${props => (props.noPadding ? `padding-top: 0` : `padding-top: ${getSpacing(3)};`)}
`;

export const CheckboxWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  margin-top: 2px;
`;

export const LabelWrapper = styled.div`
  margin-left: ${getSpacing(3)};
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: ${props =>
    props.checked ? defaultTheme.colors.healthBlue : defaultTheme.colors.white};
  border-radius: 4px;
  transition: all 150ms;
  border: 1px solid ${props => (!props.checked ? defaultTheme.colors.secondaryGrey : 'transparent')};

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
