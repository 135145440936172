import React from 'react';

export type CheckedProps = {
  checked: boolean;
};

export const Checked: React.FC<CheckedProps> = ({ checked }) => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M12.0234 5.99197C12.0234 9.30872 9.33441 11.9974 6.01795 11.9974C2.70167 11.9974 0.0126953 9.30872 0.0126953 5.99197C0.0126953 2.67623 2.70156 -0.0125275 6.01795 -0.0125275C9.33441 -0.0125275 12.0234 2.67623 12.0234 5.99197Z"
          fill={checked ? '#71BF8C' : '#E9E9ED'}
        />
        <path
          d="M5.01705 8.79734L2.61328 6.39346L3.41413 5.59226L5.01705 7.19489L8.62253 3.58894L9.42338 4.39044L5.01705 8.79734Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="12.0363" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
