import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';
import { breakpoints } from 'stylesheet';

export const Styles = {
  Wrapper: styled.div<{ inline: boolean; maxWidth?: string }>`
    display: flex;
    flex-direction: ${props => (props.inline ? 'row' : 'column')};
    margin-bottom: ${defaultTheme.spacing(2)};
    margin-top: ${defaultTheme.spacing(4)};
    max-width: ${props => props.maxWidth && props.maxWidth};
  `,
  Content: styled.div<{ initialColumns: number }>`
    columns: ${props => props.initialColumns};
    @media (max-width: ${breakpoints.small}) {
      columns: 1;
    }
  `,
};
