import { gql } from '@apollo/client';

export const putEmployeesMutation = gql`
  mutation putEmployees(
    $businessUnitId: String!
    $companyId: String!
    $employees: [EmployeeInput!]!
  ) {
    putEmployees(businessUnitId: $businessUnitId, companyId: $companyId, employees: $employees) {
      jobTitle
    }
  }
`;
