import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Styles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Image: styled.img<{ size: number }>`
    border-radius: ${props => props.size / 2}px;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
  `,
  Avatar: styled.div<{ size: number }>`
    border-radius: ${props => props.size / 2}px;
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    background-color: ${defaultTheme.colors.blueGrey};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};
