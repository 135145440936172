import React from 'react';
import { TEST_IDS } from 'constants/testIds';
import { Header, HeaderContent, Title, Subtitle, Close } from './styles';
import { useModal } from 'contexts/ModalProvider/ModalProvider';
import { Employee } from '../../../../types/graphql';
import { Spacer } from 'components/Spacer/Spacer';

type ModalHeaderTypes = {
  employee?: Employee;
};

export const ModalHeader: React.FC<ModalHeaderTypes> = ({ employee }) => {
  const { handleModal } = useModal();

  return (
    <Header>
      <HeaderContent>
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.20561 10.1049C2.95424 10.1049 0.0621425 12.5891 0.0621425 17.1002C0.0621425 17.4525 0.347689 17.7381 0.700006 17.7381H15.7112C16.0635 17.7381 16.349 17.4525 16.349 17.1002C16.3491 12.5894 13.457 10.1049 8.20561 10.1049ZM1.36108 16.4623C1.61198 13.0895 3.91148 11.3806 8.20561 11.3806C12.4997 11.3806 14.7993 13.0895 15.0504 16.4623H1.36108Z"
            fill="#838394"
          />
          <path
            d="M8.20554 0.262338C5.79332 0.262338 3.97435 2.1179 3.97435 4.57838C3.97435 7.11093 5.87245 9.17103 8.20554 9.17103C10.5386 9.17103 12.4367 7.11093 12.4367 4.57859C12.4367 2.1179 10.6178 0.262338 8.20554 0.262338ZM8.20554 7.89551C6.57579 7.89551 5.25008 6.40756 5.25008 4.57859C5.25008 2.8168 6.49308 1.53807 8.20554 1.53807C9.8906 1.53807 11.161 2.84506 11.161 4.57859C11.161 6.40756 9.8353 7.89551 8.20554 7.89551Z"
            fill="#838394"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.12412 11.8637C3.51869 10.6658 5.5711 10.0427 8.20561 10.0427C10.8401 10.0427 12.8925 10.6659 14.2871 11.8638C15.6832 13.0631 16.4112 14.8309 16.4112 17.1002C16.4112 17.4868 16.0978 17.8002 15.7112 17.8002H0.700006C0.313369 17.8002 0 17.4868 0 17.1002C0 14.8307 0.728046 13.063 2.12412 11.8637ZM1.36108 16.4623H15.0504C15.0488 16.4415 15.0472 16.4208 15.0455 16.4002C14.7709 13.0685 12.4733 11.3806 8.20561 11.3806C3.93793 11.3806 1.64039 13.0685 1.36595 16.4002C1.36425 16.4208 1.36263 16.4415 1.36108 16.4623ZM1.42831 16.4002H14.9831C14.847 14.7615 14.2179 13.5314 13.1071 12.7066C11.9789 11.8688 10.3457 11.4428 8.20561 11.4428C6.06552 11.4428 4.43229 11.8688 3.30418 12.7066C2.1934 13.5314 1.56437 14.7615 1.42831 16.4002ZM3.91221 4.57838C3.91221 2.08475 5.75784 0.200195 8.20554 0.200195C10.6532 0.200195 12.4989 2.08475 12.4989 4.57859C12.4989 7.14036 10.5776 9.23317 8.20554 9.23317C5.83343 9.23317 3.91221 7.14036 3.91221 4.57838ZM5.31223 4.57859C5.31223 6.38017 6.61666 7.83336 8.20554 7.83336C9.79443 7.83336 11.0989 6.38017 11.0989 4.57859C11.0989 2.87771 9.85463 1.60021 8.20554 1.60021C6.52905 1.60021 5.31223 2.84944 5.31223 4.57859ZM3.97435 4.57838C3.97435 2.1179 5.79332 0.262338 8.20554 0.262338C10.6178 0.262338 12.4367 2.1179 12.4367 4.57859C12.4367 7.11093 10.5386 9.17103 8.20554 9.17103C5.87245 9.17103 3.97435 7.11093 3.97435 4.57838ZM5.25008 4.57859C5.25008 6.40756 6.57579 7.89551 8.20554 7.89551C9.8353 7.89551 11.161 6.40756 11.161 4.57859C11.161 2.84506 9.8906 1.53807 8.20554 1.53807C6.49308 1.53807 5.25008 2.8168 5.25008 4.57859ZM0.0621425 17.1002C0.0621425 12.5891 2.95424 10.1049 8.20561 10.1049C13.457 10.1049 16.3491 12.5894 16.349 17.1002C16.349 17.4525 16.0635 17.7381 15.7112 17.7381H0.700006C0.347689 17.7381 0.0621425 17.4525 0.0621425 17.1002Z"
            fill="#838394"
          />
        </svg>
        <Spacer.Row numberOfSpaces={4} />
        <div>
          <Title>{employee ? 'Update' : 'Create'} employee account</Title>
          <Subtitle>
            {employee
              ? 'Edit all the details of your employee below'
              : 'Employee will receive an invitation by email'}
          </Subtitle>
        </div>
      </HeaderContent>
      <Close onClick={handleModal} data-testid={TEST_IDS.modal.closeButton}>
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.00781 11.375C0.742188 11.6406 0.734375 12.0938 1.00781 12.3594C1.28125 12.625 1.73438 12.625 2 12.3594L7 7.35938L12 12.3594C12.2656 12.625 12.7266 12.6328 12.9922 12.3594C13.2578 12.0859 13.2578 11.6406 12.9922 11.375L7.99219 6.36719L12.9922 1.36719C13.2578 1.10156 13.2656 0.648438 12.9922 0.382812C12.7188 0.109375 12.2656 0.109375 12 0.382812L7 5.38281L2 0.382812C1.73438 0.109375 1.27344 0.101562 1.00781 0.382812C0.742188 0.65625 0.742188 1.10156 1.00781 1.36719L6.00781 6.36719L1.00781 11.375Z"
            fill="#212240"
          />
        </svg>
      </Close>
    </Header>
  );
};
