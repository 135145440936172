import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Container = styled.div`
  max-height: calc(100% - 51px);
  overflow: auto;
  font-size: 13px;
  border-radius: 0 0 ${getSpacing(1)} ${getSpacing(1)};
  position: relative;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TableHead = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  padding: ${getSpacing(4)};
  border-radius: 4px 4px 0 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
