import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Wrapper = styled.div<{ inline: boolean }>`
  display: flex;
  flex-direction: ${props => (props.inline ? 'row' : 'column')};
  margin-bottom: ${getSpacing(2)};
`;

export const Input = styled.input`
  margin-right: ${getSpacing(2)};
`;

export const Span = styled.span`
  position: relative;
  top: -2px;
  color: ${({ theme }) => theme.colors.deepBlue};
`;
