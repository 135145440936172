import React from 'react';
import { Title, Subtitle } from './styles';

type HeaderProps = {
  title: string;
  subtitle?: string;
  disabled?: boolean;
};

export const Header: React.FC<HeaderProps> = ({ title, subtitle, disabled }) => {
  return (
    <>
      <Title hasSubtitle={subtitle ? true : false} disabled={disabled}>
        {title}
      </Title>
      {subtitle && <Subtitle disabled={disabled}>{subtitle}</Subtitle>}
    </>
  );
};
