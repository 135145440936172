import { useQuery } from '@apollo/client';
import { getBusinessUnitIdAndCompanyId } from 'queries/businessUnitId';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';

type BusinessUnit = {
  businessUnitId: string;
  companyId: string;
};

const initialBusinessUnit = { businessUnitId: '', companyId: '' };

export const useBusinessUnit = (): BusinessUnit => {
  const { user } = useAuth();
  const { data } = useQuery(getBusinessUnitIdAndCompanyId, {
    skip: !user || user.isVTailAdmin,
    variables: {
      businessUnitAdminId: user && user?.auth.sub,
    },
  });

  if (data) {
    initialBusinessUnit.businessUnitId = data.getBusinessUnitAdminByCognitoId.businessUnitId;
    initialBusinessUnit.companyId = data.getBusinessUnitAdminByCognitoId.companyId;
  }

  return initialBusinessUnit;
};
