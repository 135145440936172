import { gql } from '@apollo/client';

export const getEmployees = gql`
  query getEmployeesByBusinessUnitId(
    $limit: Int
    $lastEvaluatedKey: LastEvaluatedKeyInput
    $businessUnitId: String!
  ) {
    getEmployeesByBusinessUnitId(
      limit: $limit
      lastEvaluatedKey: $lastEvaluatedKey
      businessUnitId: $businessUnitId
    ) {
      items {
        id
        firstName
        lastName
        sitesOfCareIds
        areasOfPracticeIds
        email
        jobTitle
        userStatus
      }
      lastEvaluatedKey {
        PK
        SK
      }
    }
  }
`;
