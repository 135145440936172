import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export type LabelProps = {
  margin?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
};

export const Label = styled.label<LabelProps>`
  display: block;
  font-size: ${({ fontSize }) => fontSize ?? '14px'};
  margin: ${({ margin }) => (margin ? margin : `0 0 ${getSpacing(2)} 0`)};
  color: ${({ theme, color }) => color ?? theme.colors.darkGrey};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
`;

export default Label;
