import { gql } from '@apollo/client';

export const getEmployee = gql`
  query getEmployee($employeeId: String!) {
    getEmployee(employeeId: $employeeId) {
      id
      areasOfPracticeIds
      sitesOfCareIds
      areasOfSpecialisationIds
      email
      firstName
      jobTitle
      lastName
      companyId
      businessUnitId
      zipcode
      userStatus
      state {
        name {
          en
        }
      }
      profileImageUrl
    }
  }
`;
