import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Tag = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-right: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  padding: ${getSpacing(1)} ${getSpacing(3)};
  background-color: ${({ theme }) => theme.colors.separator};
  border-radius: 20px;
`;

export default Tag;
