import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

type ContentProps = {
  open: boolean;
};

export const Wrapper = styled.div`
  width: 100%;
`;

export const Heading = styled.h2<ContentProps>`
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  background: ${({ theme }) => theme.colors.backgroundGrey}};
  color: ${({ theme }) => theme.colors.deepBlue};
  padding: ${getSpacing(4)};
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 150%;
  font-size: 14px;
  border-top-left-radius: ${getSpacing(1)};
  border-top-right-radius: ${getSpacing(1)};
  border-bottom-left-radius: ${({ open }) => {
    return open ? 0 : getSpacing(1);
  }}};
  border-bottom-right-radius: ${({ open }) => {
    return open ? 0 : getSpacing(1);
  }}};
  user-select: none;
`;

export const Content = styled.div<ContentProps>`
  border: 1px solid ${({ theme }) => theme.colors.borderGrey};
  border-top: none;
  opacity: ${({ open }) => (open ? '1' : '0')};
  max-height: ${({ open }) => (open ? '100%' : '0')};
  overflow: hidden; 
  padding: ${({ open }) => (open ? getSpacing(4) : `0 ${getSpacing(4)}`)};
  transition: all 0.3s;
  border-bottom-left-radius: ${({ open }) => {
    return open ? getSpacing(1) : 0;
  }}};
  border-bottom-right-radius: ${({ open }) => {
    return open ? getSpacing(1) : 0;
  }}};
`;

export const ContentData = styled.div<ContentProps>`
  margin: 0px;
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

export const FloatRight = styled.div`
  float: right;
`;
