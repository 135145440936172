// @ts-nocheck
/* eslint-disable */
import { defaultTheme } from 'constants/theme/defaultTheme';
import { fontFamily, getSpacing } from 'stylesheet';

export const singleSelectStyles = {
  container: provided => ({
    ...provided,
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: 14,
    fontFamily: fontFamily.main,
    fontWeight: 500,
  }),
  option: provided => ({
    ...provided,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: fontFamily.main,
    ':hover': {
      cursor: 'pointer',
    },
  }),
  control: provided => ({
    ...provided,
    borderRadius: getSpacing(2),
    borderWidth: 0,
    boxShadow: '0px 2px 4px rgba(20, 37, 59, 0.04)',
    minHeight: 48,
    ':hover': {
      cursor: 'pointer',
    },
  }),
  noOptionsMessage: provided => ({
    ...provided,
    fontFamily: fontFamily.main,
    fontSize: 13,
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: 14,
    fontFamily: fontFamily.main,
    fontWeight: 500,
    color: defaultTheme.colors.secondaryGrey,
    paddingLeft: getSpacing(2),
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '8px',
    minHeight: '40px',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    borderColor: defaultTheme.colors.borderGrey,
  }),
};
