import React, { useState } from 'react';
import { Button } from 'atoms/Button/Button';
import { Field } from 'atoms/Field/Field';
import { Header } from 'atoms/Header/Header';
import { StyledForm, Footer, Back, FieldContent } from './styles';
import { useFormik } from 'formik';
import { AddSOCFormSchema } from './AddSOCForm.schema';
import { ItemInput } from 'models/itemInput';
import { Checkboxes } from 'atoms/Checkboxes/Checkboxes';
import { RadioButtons } from 'atoms/RadioButtons';
import {
  handleCheckboxSelect,
  setSelectedAosSitesOfCare,
  setAdditionalSitesOfCare,
  setCommonSitesOfCare,
  getAdditionalSocs,
} from './AddSOCForm.helpers';
import { TEST_IDS } from 'constants/testIds';

export type SOCFormValues = {
  soc: string[];
};

type AddSOCFormProps = {
  setShowAddSOC: (showAos: boolean) => void;
  currentAosValues: ItemInput[];
  currentSocValues: ItemInput[];
  onAddSOC: (showAos: SOCFormValues) => void;
  currentSocIds: string[];
};

export const AddSOCForm: React.FC<AddSOCFormProps> = ({
  setShowAddSOC,
  currentAosValues,
  currentSocValues,
  onAddSOC,
}) => {
  const initialValues: SOCFormValues = {
    soc: currentSocValues.map(item => item.id) || [],
  };

  const { handleSubmit, isValid, values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: onAddSOC,
    validationSchema: AddSOCFormSchema,
  });

  const initialShow = !!getAdditionalSocs(currentAosValues, currentSocValues).length
    ? 'true'
    : 'false';
  const [showAdditionalSoc, setShowAdditionalSoc] = useState(initialShow);

  const commonSitesOfCare = setCommonSitesOfCare(currentAosValues);
  const selectedSitesOfCare = setSelectedAosSitesOfCare(currentAosValues);
  const additionalSitesOfCare = setAdditionalSitesOfCare(currentAosValues);

  return (
    <StyledForm onSubmit={handleSubmit} autoComplete="off">
      {!!currentSocValues.length && !!commonSitesOfCare.length && (
        <Field>
          <FieldContent>
            <Header
              title="Sites of care"
              subtitle="Sites of care that are common for all areas of specialization"
            />
            <Checkboxes
              inline
              initialColumns={2}
              options={commonSitesOfCare}
              selected={values.soc}
              handleChange={val => handleCheckboxSelect(setFieldValue, val, values.soc, 'soc')}
            />
          </FieldContent>
        </Field>
      )}
      {!!selectedSitesOfCare.length &&
        selectedSitesOfCare.map(soc => (
          <Field key={soc.category}>
            <FieldContent data-testid={TEST_IDS.addSOCForm.primaryCheckboxes}>
              <Header
                title={`${soc.category} sites of care`}
                subtitle={`Sites of care that are specific to ${soc.category}`}
              />
              <Checkboxes
                inline
                options={soc.sitesOfCare}
                initialColumns={2}
                selected={values.soc}
                handleChange={val => handleCheckboxSelect(setFieldValue, val, values.soc, 'soc')}
              />
            </FieldContent>
          </Field>
        ))}
      {!!additionalSitesOfCare.length && (
        <div data-testid={TEST_IDS.addSOCForm.additionalSitesRadio}>
          <Header
            title="Additional Sites of care"
            subtitle="Does this employee sell products into sites of care outside this area of specialization?"
          />
          <RadioButtons
            inline
            options={[
              { name: 'Yes', value: 'true' },
              { name: 'No', value: 'false' },
            ]}
            handleChange={e => setShowAdditionalSoc(e.target.value)}
            selectedOption={showAdditionalSoc}
          />
          {showAdditionalSoc === 'true' &&
            additionalSitesOfCare.map(soc => (
              <Field
                key={soc.category}
                data-testid={`soc-add-category-${
                  soc.category ? soc.category.toLowerCase().replace(' ', '-') : ''
                }`}
              >
                <FieldContent>
                  <Header title={`${soc.category} sites of care`} />
                  <Checkboxes
                    inline
                    initialColumns={2}
                    options={soc.sitesOfCare}
                    selected={values.soc}
                    handleChange={val =>
                      handleCheckboxSelect(setFieldValue, val, values.soc, 'soc')
                    }
                  />
                </FieldContent>
              </Field>
            ))}
        </div>
      )}
      <Footer>
        <Back onClick={() => setShowAddSOC(false)}>Back</Back>
        <Button
          text="Save sites of care"
          type="submit"
          data-testid={TEST_IDS.addSOCForm.submit}
          disabled={!isValid || !values.soc.length}
        />
      </Footer>
    </StyledForm>
  );
};
