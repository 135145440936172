import React from 'react';
import Label from 'atoms/Label';
import { defaultTheme } from 'constants/theme/defaultTheme';
import {
  Wrapper,
  CheckboxWrapper,
  StyledCheckbox,
  Icon,
  HiddenCheckbox,
  LabelWrapper,
} from './styles';
import { LabelProps } from 'atoms/Label/Label';

type CheckboxProps<V> = {
  label: string;
  labelProps?: LabelProps;
  checked: boolean;
  onChange: (value: V) => void;
  name: string;
  value: V;
  last?: boolean;
  disabled?: boolean;
  noPadding?: boolean;
};

interface CheckboxComponentProps {
  name: string;
  checked: boolean;
}
export const CheckboxComponent: React.FC<CheckboxComponentProps> = props => (
  <CheckboxWrapper>
    <HiddenCheckbox name={props.name} data-testid={`checkbox-${props.name}`} />
    <StyledCheckbox checked={props.checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxWrapper>
);

export const Checkbox = <G,>({
  label,
  labelProps,
  checked,
  name,
  onChange,
  value,
  last,
  noPadding,
}: CheckboxProps<G>) => {
  return (
    <Wrapper onClick={() => onChange(value)} last={last} noPadding={noPadding}>
      <CheckboxComponent name={name} checked={checked} />
      <LabelWrapper>
        <Label margin="0" color={defaultTheme.colors.black} {...(labelProps ?? {})}>
          {label}
        </Label>
      </LabelWrapper>
    </Wrapper>
  );
};
