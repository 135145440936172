import { gql } from '@apollo/client';

export const getBusinessUnitIdAndCompanyId = gql`
  query getBusinessUnitAdminByCognitoId($businessUnitAdminId: String!) {
    getBusinessUnitAdminByCognitoId(businessUnitAdminId: $businessUnitAdminId) {
      businessUnitId
      companyId
    }
  }
`;
