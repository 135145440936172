import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Styles = {
  Header: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${getSpacing(4)} ${getSpacing(6)};
    border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
    border-radius: ${({ theme }) => `${theme.borderRadius} ${theme.borderRadius} 0 0`};
  `,
  Person: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Meta: styled.div`
    margin-left: ${getSpacing(4)};
  `,
  H2: styled.h2`
    font-weight: 600;
    font-size: 15px;
  `,
  HeaderActions: styled.a`
    font-size: 15px;
    font-weight: 500;
    margin-right: ${getSpacing(2)};
    color: ${({ theme }) => theme.colors.healthBlue};

    &:hover {
      cursor: pointer;
    }
  `,
  Position: styled.p`
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.darkGrey};
  `,
  Content: styled.div`
    padding: ${getSpacing(8)} ${getSpacing(6)};
    overflow: auto;
    height: calc(70vh - 81px);
    min-height: calc(465px - 94px);
  `,
  Row: styled.div`
    display: flex;
    margin-bottom: ${getSpacing(6)};
  `,
  IconRow: styled.div`
    display: flex;
    margin-bottom: ${getSpacing(6)};
    display: flex;
    margin-bottom: ${getSpacing(6)};
    align-items: center;
  `,
  Field: styled.div`
    flex: 1;
  `,
  Label: styled.label`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-weight: 500;
    margin-bottom: ${getSpacing(2)};
    display: block;
  `,
  Value: styled.p`
    font-size: 14px;
    font-weight: 500;
  `,
  IconValue: styled.p`
    font-size: 14px;
    font-weight: 500;
    margin-left: ${getSpacing(2)};
  `,
  Tags: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  Message: styled.div`
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    padding-left: ${getSpacing(8)};
    padding-right: ${getSpacing(8)};
  `,
  ResendLink: styled.div`
    border: none;
    outline: none;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    padding-top: ${getSpacing(1)};

    color: ${({ theme }) => theme.colors.healthBlue};
  `,
};
