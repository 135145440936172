import { EditCompanyFormValues } from 'components/AdminEditCompanyModal/AdminEditCompanyModal';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { ItemInput } from 'models/itemInput';
import { CompanyLabelsItem, CompanyCategoriesItem } from '../../../../types/graphql';

export type AddCompanyFormValues = {
  name: string;
  description: string;
  logoUrl: string;
  website: string;
  heroImageUrl: string;
  weighting: number | undefined;
  areasOfSpecialisation: ItemInput[];
  areasOfPractice: ItemInput[];
  contactUrl: string;
  featured: boolean;
  shortDescription: string;
  label: CompanyLabelsItem[];
  categories: CompanyCategoriesItem[];
};

export enum CompanyLogoType {
  logoUrl = 'logoUrl',
  heroImageUrl = 'heroImageUrl',
}

export enum CompanyLogoTypeToText {
  logoUrl = 'Company Logo',
  heroImageUrl = 'Hero Image',
}

export enum CompanyLogoBackendType {
  COMPANY_LOGO = 'COMPANY_LOGO',
  HERO_IMAGE = 'COMPANY_HERO_IMAGE',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
}

export type AddEditCompanyForm = AddCompanyFormValues | EditCompanyFormValues;

// formik doesn't expose this type
// so manually extracting it from the useFormik hook
// @ts-ignore
const typeWrapper = () => useFormik<AddEditCompanyForm>();
export type FormikCompany = ReturnType<typeof typeWrapper>;

export const isAddCompanyFormValues = (
  form: AddEditCompanyForm,
  editMode: boolean,
): form is AddCompanyFormValues => {
  return !editMode;
};

export const isAddCompanyFormErrors = (
  errors: FormikErrors<AddEditCompanyForm>,
  editMode: boolean,
): errors is FormikErrors<AddCompanyFormValues> => {
  return !editMode;
};

export const isAddCompanyFormTouched = (
  errors: FormikTouched<AddEditCompanyForm>,
  editMode: boolean,
): errors is FormikTouched<AddCompanyFormValues> => {
  return !editMode;
};
