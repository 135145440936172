import React, { MutableRefObject, RefObject, useRef } from 'react';
import { Employee } from '../../../../types/graphql';
import { Div } from './styles';
import { Checked } from 'assets/svgs/Checked';

export type Props = {
  employee: Employee;
};

const UserStatusIcon: React.FC<Props> = ({ employee }) => {
  const iconRef: MutableRefObject<undefined> = useRef();

  const content = `${employee.userStatus} account`;
  const activated = employee.userStatus === 'Activated';

  return (
    <Div ref={iconRef as unknown as RefObject<HTMLDivElement>} title={content}>
      <Checked checked={activated} />
    </Div>
  );
};

export default UserStatusIcon;
