import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${getSpacing(10)};
  height: 90vh;
  overflow: auto;
`;

export const Footer = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Back = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const FieldContent = styled.div`
  min-height: 100px;
`;
