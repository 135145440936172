import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { SingleSelect } from 'atoms/SingleSelect/SingleSelect';
import { useCompany } from 'contexts/CompanyProvider/CompanyProvider';
import {
  formatCompaniesDropdown,
  getInitialCompanyDropdownValue,
} from 'utils/formatForDropdown/formatForDropdown';
import Label from 'atoms/Label';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { DropdownValue } from 'models/global';

type EmployeesActionsProps = {
  getEmployeesByCompanyId: (companyId: string) => void;
  selectedCompanyId: string;
};

export const AdminEmployeeActions: React.FC<EmployeesActionsProps> = ({
  getEmployeesByCompanyId,
  selectedCompanyId,
}) => {
  const { companies } = useCompany();
  const [value, setValue] = useState<DropdownValue>();

  useEffect(() => {
    if (companies) {
      setValue(getInitialCompanyDropdownValue(companies, selectedCompanyId));
    }
  }, [companies, selectedCompanyId]);

  const handleSelect = (option: DropdownValue | null) => {
    if (option) {
      getEmployeesByCompanyId(option.value);
      return;
    }

    setValue(undefined);
  };

  const companiesFormatted = companies ? formatCompaniesDropdown(companies) : [];

  return (
    <Wrapper>
      <Label color={defaultTheme.colors.darkGrey} margin={`0 0 ${defaultTheme.spacing(1)}`}>
        Choose Company
      </Label>
      <SingleSelect
        options={companiesFormatted}
        handleSelect={handleSelect}
        value={value}
        name="companies"
        placeholder="Type company name..."
      />
    </Wrapper>
  );
};
