import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const Title = styled.p<{ hasSubtitle: boolean; disabled?: boolean }>`
  color: ${props =>
    props.disabled ? defaultTheme.colors.disabledGrey : defaultTheme.colors.deepBlue};
  font-weight: 600;
  font-size: 14px;
  line-height: 1em;
  margin-bottom: ${props => (props.hasSubtitle ? getSpacing(1) : getSpacing(2))};
`;

export const Subtitle = styled.label<{ disabled?: boolean }>`
  display: block;
  color: ${props =>
    props.disabled ? defaultTheme.colors.disabledGrey : defaultTheme.colors.darkGrey};
  font-size: 13px;
  line-height: 1em;
  margin-bottom: ${getSpacing(2)};
`;
