import React, { useState } from 'react';
import { Styles } from './styles';
import { AdminEmployeeActions } from 'components/AdminEmployeeActions/AdminEmployeeActions';
import { AdminEmployeesTable } from 'components/AdminEmployeesTable/AdminEmployeesTable';
import { getEmployee } from 'queries/employee';
import { ApolloQueryResult, useLazyQuery, useQuery } from '@apollo/client';
import { ProfileDetails } from 'components/ProfileDetails/ProfileDetails';
import { getEmployeesByCompanyId } from 'queries/company';
import { useRouter } from 'next/router';
import { PATHS } from 'constants/paths';
import { AdminWrapper } from '../components/AdminWrapper/AdminWrapper';
import { apolloClient } from 'services/apollo/client';

// manipulate cache directly so b/e doesn't error looking for employee id thats been archived
export const refetchData = (
  employeeId: string,
  refetch: (
    variables?: Partial<{ companyId: string }> | undefined,
  ) => Promise<ApolloQueryResult<unknown>>,
) => {
  apolloClient.writeQuery({
    query: getEmployee,
    data: { getEmployee: null },
    variables: {
      employeeId,
    },
  });
  refetch();
};

export const Employees: React.FC = () => {
  const { query, push } = useRouter();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    (query.companyId as string | undefined) || '',
  );
  const { loading, data, refetch } = useQuery(getEmployeesByCompanyId, {
    variables: { companyId: selectedCompanyId },
    skip: !selectedCompanyId,
  });
  const [fetchEmployee, { loading: employeeLoading, data: selectedEmployee }] =
    useLazyQuery(getEmployee);

  const handleGetEmployeesByCompanyId = (companyId: string): void => {
    setSelectedCompanyId(companyId);
    setSelectedEmployeeId('');
    push(
      {
        pathname: PATHS.ADMIN_EMPLOYEES,
        query: `companyId=${companyId}`,
      },
      undefined,
      {},
    );
  };

  const handleGetEmployee = (employeeId: string): void => {
    setSelectedEmployeeId(employeeId);
    fetchEmployee({
      variables: {
        employeeId,
      },
    });
  };

  const deleteEmployeeAndFetch = (id: string) => refetchData(id, refetch);

  return (
    <AdminWrapper>
      <AdminEmployeeActions
        getEmployeesByCompanyId={handleGetEmployeesByCompanyId}
        selectedCompanyId={selectedCompanyId}
      />
      <Styles.Results>
        <AdminEmployeesTable getEmployee={handleGetEmployee} loading={loading} data={data} />
        <ProfileDetails
          loading={employeeLoading}
          data={!!selectedEmployeeId ? selectedEmployee : null}
          refetchData={deleteEmployeeAndFetch}
        />
      </Styles.Results>
    </AdminWrapper>
  );
};
