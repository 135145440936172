import React, { ChangeEvent } from 'react';
import Label from 'atoms/Label';
import { Wrapper, Input, Span } from './styles';

type Option = {
  name: string;
  value: string;
};

type RadioButtonsProps = {
  options: Option[];
  selectedOption: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inline?: boolean;
};

export const RadioButtons: React.FC<RadioButtonsProps> = ({
  options,
  selectedOption,
  handleChange,
  inline,
}) => {
  return (
    <Wrapper inline={inline ? true : false} data-testid="radio-buttons">
      {options.map(option => {
        return (
          <Label key={option.value}>
            <Input
              type="radio"
              name={option.name}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={handleChange}
              data-testid={`radio-option-${option.value}`}
            />
            <Span>{option.name}</Span>
          </Label>
        );
      })}
    </Wrapper>
  );
};
